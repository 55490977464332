.auth-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px 50px;
  position: relative;

  .border-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    border-top: 4px solid #f4811e;
  }

  @media only screen and (max-width: 768px) {
    padding: 25px 20px;
  }

  svg {
    @media only screen and (max-width: 468px) {
      width: 80%;
      height: auto;
    }
  }

  .a-h-right {
    color: #1f1f1f;
    font-weight: 500;

    span {
      font-weight: 400;
      margin-right: 4px;

      @media only screen and (max-width: 568px) {
        display: none;
      }
    }
  }
}
