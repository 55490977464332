.permission-item-create {
  .add-drawer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    height: calc(100vh - 165px);

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      gap: 32px;
      overflow-y: scroll;
    }

    .form-fields,
    .events {
      width: 48%;

      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .events {
      min-width: 430px;

      @media only screen and (max-width: 768px) {
        min-width: unset;
      }
    }

    .event-selector {
      margin-top: 24px;
      height: calc(100vh - 240px);

      @media only screen and (max-width: 768px) {
        height: unset;
      }

      .event-group {
        @media only screen and (max-width: 898px) {
          flex-direction: column;

          .e-group-name {
            width: 100%;
            max-width: unset;
          }
        }
      }
    }

    .add-drawer-footer {
      background: #f0f0f0;
    }
  }
}

.event-selector {
  border: 1px solid #ebebeb;
  border-radius: 10px;
  padding: 16px;
  min-height: 150px;
  background: #ffffff;

  .event-group {
    &:not(:last-child) {
      margin-bottom: 30px;
    }

    .e-group-name {
      color: #2e2e2e;
      font-size: 14px;
      width: 30%;
      max-width: 130px;
    }
  }
}

.gray-bg {
  .mantine-Drawer-drawer {
    background: #f8faf9;
  }
}

.overflow-modal {
  .mantine-Drawer-body {
    overflow-y: scroll;
    max-height: calc(100vh - 155px);
  }
}

.permission-item-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;

  @media only screen and (max-width: 868px) {
    flex-direction: column;
    gap: 24px;
  }

  .p-i-info {
    width: 52%;
    display: block;

    @media only screen and (max-width: 868px) {
      width: 100%;
    }

    .p-i-main {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media only screen and (max-width: 568px) {
        flex-direction: column-reverse;

        .detail-item {
          padding: 8px 0;
        }
      }

      & > div {
        width: 48%;

        @media only screen and (max-width: 568px) {
          width: 100%;
        }
      }
    }
  }

  .permission-list {
    margin-top: 64px;

    .p-l-title {
      color: #2e2e2e;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }

  .p-tabs-container {
    width: 46%;
    padding: 18px 24px;
    background: #ffffff;
    border: 1px solid #dedede;
    box-shadow: 0px 4px 24px -16px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    @media only screen and (max-width: 868px) {
      width: 100%;
    }

    .mantine-Tabs-tab {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.fullscreen-modal-section-scroll {
  height: calc(100vh - 100px);

  @media only screen and (min-width: 569px) {
    padding-right: 16px;
  }

  &.child {
    @media only screen and (max-width: 868px) {
      height: unset;
      padding-right: unset;
    }
  }

  &.allow-grow {
    height: fit-content;
    min-height: calc(100vh - 100px);
  }
}

.test-webhook {
  .mantine-Select-root {
    width: calc(100% - 76px);
  }
}
