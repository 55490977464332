.add-drawer {
  .add-scrollable-section {
    height: calc(100vh - 155px);

    &.with-tabs {
      height: calc(100vh - 205px);
    }
  }

  .add-drawer-footer {
    position: absolute;
    padding: 20px;
    left: 0;
    bottom: 0;
    right: 0;
    background: #f8f8f8;

    button {
      min-width: 83px;
    }
  }
}

.member-c {
  padding-bottom: 30px;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 18px;
}

.group-card-item {
  border: 1px solid #f2f2f2;
  border-radius: 6px;

  .detail-item {
    padding-left: 16px;
    padding-right: 16px;

    &:last-child {
      border-bottom: unset;
    }
  }
}

.roles-list {
  padding-bottom: 25px;
  border-bottom: 1px solid #e6e6e67b;

  .r-l-title {
    color: #7f7f7f;
    font-size: 14px;
    margin-bottom: 8px;
  }

  .r-l-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .r-l-item-name {
      min-height: 33px;
      padding: 6px 12px;
      color: #000000;
      font-size: 13px;
      border: 1.5px solid #f8f8f8;
      border-radius: 6px;
      width: calc(100% - 40px);
    }
  }
}

.select-role {
  width: calc(100% - 40px);
  color: #7f7f7f;
  padding: 6px 12px;
  border: 1.5px solid #f8f8f8;
  border-radius: 6px;
  font-size: 13px;

  input {
    border: 1.5px solid #f8f8f8;
    border-radius: 6px;
  }
}
