.onboarding {
  .welcome-user {
    color: #121212;
    font-weight: 700;
    font-size: 23px;
    margin-bottom: 12px;
  }

  .welcome-desc {
    color: #444444;
    font-size: 14px;
  }

  .onboarding-main {
    margin-top: 38px;
    padding: 0 16px;
    background: #fbfbfb;
    border-radius: 8px;

    .o-item {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      padding: 22px 0;

      &:not(:last-child) {
        border-bottom: 0.3px solid #bbbbbb;
      }

      &.active {
        align-items: flex-start;
      }

      .text-content {
        .t-c-title {
          color: #121212;
          font-weight: 500;
          font-size: 18px;
          margin-bottom: 5px;
        }

        .t-c-desc {
          font-weight: 500;
          font-size: 12px;
          color: #868686;
          margin-bottom: 16px;
        }

        .onboard-action {
          color: #f69a4b;
          font-weight: 500;
          text-decoration: underline;
        }
      }

      .arrow {
        position: absolute;
        right: 16px;
        top: 26px;
        transition: 0.3s;

        @media only screen and (max-width: 438px) {
          right: 0px;
        }

        &.rotate {
          -o-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }
    }
  }
}
