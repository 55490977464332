.group-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .group-select-item {
    width: 49%;
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #ffffff;

    @media only screen and (max-width: 868px) {
      padding: 16px 12px;
    }

    @media only screen and (max-width: 568px) {
      padding: 12px;
    }

    &.selected {
      border: 1px solid #3db9cf;
    }
  }
}

.apikey-token {
  .mantine-Prism-code {
    background: rgba(214, 89, 64, 0.05) !important;
    border-radius: 6px;
    color: #d65940 !important;
  }
}
