.empty-content {
  width: 100%;
  max-width: 633px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px auto 0;
  text-align: center;
  margin-bottom: 40px;

  .e-c-title {
    font-weight: 700;
    font-size: 25px;
    margin: 17px 0;

    @media only screen and (max-width: 568px) {
      font-size: 24px;
    }
  }

  .e-c-desc {
    color: #808080;
  }
}
