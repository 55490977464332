.action-step {
  .a-s-top {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;

    .a-s-number {
      border: 1px solid #e6e6e6;
      border-radius: 20px;
      width: 27px;
      height: 21px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #7f7f7f;
      font-size: 13px;
      font-weight: 600;
    }

    .a-s-title {
      color: #2e2e2e;
      font-weight: 500;
    }
  }

  .a-s-desc {
    font-size: 13px;
    color: #7f7f7f;
  }
}
