.create-page {
  max-width: 800px;
  margin: 0 auto;

  .c-form-group {
    margin-bottom: 36px;

    .c-g-title {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 8px;
    }

    .c-g-content {
      box-shadow: rgb(238 239 239) 0px 0px 0px 1px inset,
        rgb(38 38 44 / 7%) 0px 1px 3px;
      border-radius: 8px;
      padding: 16px;

      .c-g-c-inner {
        margin: 16px -15px 0 -15px;
        padding: 16px 16px 10px 16px;
        background: #fcfcfc;
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
      }
    }
  }

  .form-item-with-add {
    width: calc(100% - 96px);
  }

  .mantine-Input-icon {
    color: unset;
  }

  .mantine-Input-withIcon {
    padding-left: 30px;
  }

  input,
  .czo {
    border-color: #ebebeb !important;
  }

  .c-text {
    max-width: 300px;
  }
}

.confirm-transfer {
  margin-top: -10px;

  .c-t-summary {
    border: 1px solid #e9e9e9;
    border-radius: 8px;
    padding: 24px 16px;

    .send-icon {
      background: #feebdc;
      padding: 6px;
      border-radius: 50%;
      height: 30px;
      width: 30px;
    }

    .s-row {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &.w-full {
        .s-item {
          width: 100% !important;
        }
      }

      .s-item {
        width: 48%;

        .s-i-title {
          color: #bbbbbb;
          font-weight: 500;
          font-size: 12px;
        }

        .s-i-desc {
          color: #121212;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }

  .c-t-action {
    text-align: center;

    button {
      width: 218px;
      height: 40px;
    }
  }
}

.c-p-header {
  margin-bottom: 30px;

  .h-title {
    font-size: 24px;
    font-weight: 500;
    color: #000000;
  }

  .h-routes {
    color: #b3b5b9;
    font-weight: 500;
    font-size: 14px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 7px;

    svg {
      height: 8px;
      width: auto;
      opacity: 0.5;
      transform: translateY(1px);
    }
  }

  .route-present {
    color: #575a5f;
  }
}
