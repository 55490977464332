.loggedin-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;

  .sidebar-container {
    position: relative;
    z-index: 4;
    padding-top: 20px;
    background: #ffffff;
    color: #333333;
    border-right: 0.5px solid #dddddd;

    &.is-hidden {
      @media only screen and (max-width: 1200px) {
        visibility: hidden;
      }
    }

    @media only screen and (min-width: 500px) {
      min-width: 250px;
      max-width: 250px;
    }

    @media only screen and (max-width: 500px) {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 130px;
    }

    .sidebar-ham {
      position: absolute;
      top: 18px;
      right: 10px;

      @media only screen and (max-width: 500px) {
        right: 16px;
      }

      @media only screen and (min-width: 1201px) {
        display: none;
      }
    }

    .app-logo {
      padding-left: 38px;
      margin-bottom: 20px;
    }

    .sidebar-inner {
      display: block;
      max-height: calc(100vh - 195px);
      padding: 0 17px;

      @media only screen and (max-width: 768px) {
        height: calc(100vh - 210px);
      }

      &.is-hidden {
        @media only screen and (max-width: 1200px) {
          display: none;
        }
      }

      .nav-group {
        margin-bottom: 16px;
      }

      .s-title {
        padding-left: 13px;
        font-weight: 500;
        font-size: 14px;
        color: #343434;
        margin-bottom: 4px;
      }

      .mantine-ScrollArea-thumb {
        background-color: #42424266;
      }

      .mantine-NavLink-root {
        padding: 4px 12px;
        border-radius: 4px;
        letter-spacing: 0.3px;
        font-weight: 500;
        line-height: 1.3;
        color: #222222;

        &[data-active] {
          color: rgb(0, 0, 0);
          background: #f1f1f1;
        }
      }
    }

    .env-switch-docs {
      position: absolute;
      bottom: 85px;
      right: 0;
      left: 0;
      padding: 0 12px;

      .go-to-docs {
        display: none;
      }

      @media only screen and (min-width: 769px) {
        display: none;
      }
    }

    .user-info {
      color: #000000;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 15px 12px;
      border-top: 1px solid #dddddd;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      .u-i-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        .mantine-Avatar-placeholder {
          background: #efefef;
          color: #444444;
        }

        .u-main {
          .org-name {
            font-weight: 700;
            text-transform: capitalize;
            max-width: 160px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            @media only screen and (max-width: 500px) {
              max-width: calc(100vw - 220px);
            }
          }

          .user-name {
            font-size: 12px;
            max-width: 160px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            @media only screen and (max-width: 500px) {
              max-width: calc(100vw - 220px);
            }

            span {
              color: #121212;
              font-size: 10px;
              background: #efefef;
              border-radius: 3px;
              padding: 4px;
              margin-left: 4px;
            }
          }
        }
      }
    }

    .mantine-Menu-dropdown {
      @media only screen and (min-width: 501px) {
        transform: translateX(15px);
      }
    }
  }

  .logged-m21 {
    padding-top: 74px;

    .loggedin-header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 3;
      padding: 16px 30px 16px 30px;
      background: #fbfbfb;
      border-bottom: 1px solid #ededed;

      @media only screen and (max-width: 1200px) {
        padding: 12px 20px;
      }

      @media only screen and (max-width: 568px) {
        padding: 12px 15px;
      }

      .header-items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .h-left {
          display: flex;
          align-items: center;

          .menu {
            display: none;

            @media only screen and (max-width: 1200px) {
              display: unset;
              margin-right: 10px;
            }

            &.menu-open {
              position: absolute;
              z-index: 10000000;
              top: 0;
            }
          }

          .search-container {
            margin-left: 250px;

            @media only screen and (max-width: 1200px) {
              margin-left: 0px;
            }
          }
        }

        .env-switch-docs {
          justify-content: flex-end;

          @media only screen and (max-width: 768px) {
            display: none;
          }
        }
      }
    }

    .main-section {
      position: fixed;
      top: 72px;
      right: 0;
      bottom: 0;
      left: 250px;
      overflow-y: scroll;
      padding: 30px;

      @media only screen and (max-width: 1200px) {
        left: 0;
        top: 64.67px;
      }

      @media only screen and (max-width: 768px) {
        padding: 30px 20px;
      }

      .main-section-inner {
        max-width: 1400px;
        margin: 0 auto 50px;
        position: relative;
        min-height: calc(100% - 50px);
      }
    }
  }
}

.details-drawer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.w230 {
  width: 60%;

  .mantine-PasswordInput-wrapper {
    max-width: 370px;
  }

  input {
    padding-left: 0;
  }
}

.filter-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .filter-number {
    margin-left: 6px;
    color: white;
    background: #f4811e;
    border-radius: 3px;
    padding: 2px 6px;
    font-size: 12px;
  }

  .filter-menu {
    transition: opacity 0.3s ease 0s;

    .filter-item-expanded {
      padding: 10px 16px;
      margin: 10px -16px 0;
      border-top: 1px solid #e9e9e9;
      border-bottom: 1px solid #e9e9e9;
      background: #fcfcfc;
    }

    .mantine-Switch-root {
      transform: translateY(3px);
    }
  }
}

.mantine-ScrollArea-viewport {
  padding-right: 0;
}
.mantine-ScrollArea-corner {
  display: none !important;
}
.mantine-ScrollArea-scrollbar {
  right: 0 !important;
}

.back-btn {
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: 18px;
  text-transform: lowercase;
  width: fit-content;

  span {
    margin-left: 6px;
  }
}

.p-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;

  @media only screen and (max-width: 400px) {
    font-size: 22px;
  }

  &.extra-mb {
    margin-bottom: 25px;
  }

  &.search-result {
    font-size: 18px;
    max-width: 780px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: unset !important;

    @media only screen and (max-width: 868px) {
      max-width: 660px;
    }

    @media only screen and (max-width: 768px) {
      max-width: 380px;
    }

    @media only screen and (max-width: 568px) {
      max-width: 290px;
    }

    @media only screen and (max-width: 468px) {
      max-width: 270px;
    }
  }
}

.btn-lg-only {
  margin-left: 4px;

  @media only screen and (max-width: 400px) {
    display: none;
  }
}

.compact-btn {
  font-size: 13px;
  font-weight: 500;
  padding: 1px 12px;
  border-radius: 6px;
  height: 32px;

  &.main {
    font-weight: 600 !important;
  }

  &.light {
    background: rgba(46, 46, 46, 0.1);
    border: unset;
    color: #2e2e2e;
  }
}

.metadata {
  .m-header {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid #ced4da;

    .m-title {
      font-size: 14px;
      font-weight: 500;
      color: #212529;

      span {
        color: #a9a9a9;
      }
    }
  }

  .metadata-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 10px;

    .m-r-fields {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: calc(100% - 38px);

      & > div {
        width: 49%;
      }
    }

    .mantine-ActionIcon-root {
      transform: translateY(-4px);
    }
  }
}

.mantine-Tooltip-tooltip {
  font-size: 12px !important;
}

.mantine-Tabs-tabsList {
  border-bottom: unset !important;
}

.mantine-Tabs-tab {
  color: #677189;

  &[data-active] {
    border-bottom: 2px solid black;
    font-weight: 500;
    color: black;
  }
}

.add-new {
  .add-new-main {
    margin: 33px auto 0;
    max-width: 450px;

    .i-m-title {
      font-weight: 700;
      font-size: 30px;

      @media only screen and (max-width: 568px) {
        font-size: 26px;
      }
    }
  }
}

.mantine-Stepper-root {
  @media only screen and (min-width: 827px) {
    max-width: 1100px;
    display: flex;
    gap: 30px;
  }

  .mantine-Stepper-content {
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
    padding-top: 0px;

    @media only screen and (max-width: 826px) {
      margin-left: 0;
      margin-top: 10px;
    }
  }

  .mantine-Stepper-stepLabel {
    font-size: 14px;
  }

  .mantine-Stepper-stepIcon {
    height: 24px;
    width: 24px;
    min-width: 24px;

    &[data-completed] {
      background: #595959;
      border-color: #595959;
    }

    &[data-progress] {
      border-color: #595959;
    }

    svg {
      height: 12px;
      width: 12px;
    }
  }

  .mantine-Stepper-verticalSeparator {
    top: 28px;
    left: 11.5px;
  }

  .mantine-Stepper-verticalSeparatorActive {
    border-color: #595959;
  }

  .mantine-Stepper-stepBody {
    transform: translateY(4px);
    color: #595959;
  }
}

.data-page {
  .mantine-ScrollArea-root {
    border: 1px solid #e8e8e8 !important;
    border-radius: 6px !important;
  }

  .table-container {
    min-height: 60vh;

    .mantine-Table-root {
      margin-top: unset;
    }

    th {
      color: #7f7f7f;
      font-weight: 400;
    }

    td {
      color: #677189;

      &.gray {
        color: #2e2e2e;
      }
    }
  }
}

.type-badge {
  color: #7f7f7f;
  font-weight: 600;
  padding: 6px 12px;
  background: #f0f0f0;
  border-radius: 17.5276px;
  font-size: 12px;
  line-height: 12px;
  width: fit-content;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.table-container {
  min-height: 200px;

  &.with-action-btns {
    .action-btns {
      position: absolute;
      top: -52px;
      right: 0;

      @media only screen and (max-width: 598px) {
        display: none;
      }
    }
  }

  &.no-border {
    .table-scroll-container {
      border: unset !important;
      padding: unset !important;
    }
  }

  .unstyled {
    border: 1.5px solid #e6e6e6;
    color: #7f7f7f;
  }

  .table-scroll-container {
    padding: 0 20px;

    &.nested {
      height: 70vh;

      @media only screen and (max-width: 598px) {
        min-height: 200px;
        height: 100%;
        max-height: 65vh;
      }
    }
  }

  .mantine-Table-root {
    margin-top: unset;
  }

  th {
    color: #7f7f7f !important;
    font-weight: 500 !important;
    border-bottom: unset !important;
  }

  td {
    color: #677189;

    &.gray {
      color: #2e2e2e;
    }

    &.green {
      color: #4cb27e;
    }

    &.red {
      color: #de7965;
    }
  }

  .mantine-ScrollArea-root {
    border: 1px solid #e8e8e8;
    border-radius: 6px;
  }
}

.modal-title {
  color: #2e2e2e;
  font-size: 16px;
  font-weight: 500;
}

.modal-desc {
  color: #7f7f7f;
  font-size: 13px;
}

.date-range-input {
  margin-top: 40px;

  .input-container {
    padding: 10px 0;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    cursor: pointer;

    &:first-of-type {
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      border-radius: 10px 10px 0 0;
    }

    &:last-of-type {
      border-bottom: 1px solid #e6e6e6;
      border-radius: 0 0 10px 10px;
    }
  }

  .mantine-DatePicker-root,
  .mantine-InputWrapper-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 16px;

    label {
      color: #7f7f7f;
    }

    .mantine-Input-input {
      text-align: right;
    }
  }

  .mantine-DatePicker-dropdown {
    right: 50px !important;
    left: unset !important;

    @media only screen and (max-width: 568px) {
      right: 20px !important;
    }
  }
}

.mantine-DatePicker-day {
  color: black;
  font-weight: 500;

  &[data-selected] {
    background-color: #333333 !important;
    color: #fff;
  }
}

.mantine-DatePicker-monthPickerControlActive {
  background-color: #333333 !important;
}

.direction-badge {
  font-size: 13px;
  padding: 2px 18px;
  border-radius: 10px;
  font-weight: 600;
  width: fit-content;

  &.inflow {
    color: #4cb27e;
    background: #f2fff8;
  }

  &.outflow {
    color: #de7965;
    background: rgba(222, 121, 101, 0.05);
  }
}

.btn-light {
  color: #889096;
  font-size: 13px;
  font-weight: 500;
  border: 1.79087px solid #d7dbdf;
  border-radius: 5.37261px;
  height: 30px;
  padding-right: 10px;
  padding-left: 7px;

  &.sd {
    box-shadow: 0px 0.895434px 7.16348px -3.58174px rgba(0, 0, 0, 0.6);
  }

  &.t2 {
    color: #7f7f7f;
    border: 1.5px solid #e6e6e6;
  }

  &.no-pr {
    padding-right: 0;
  }

  .mantine-Button-icon {
    margin-right: 7px;
  }

  .divider {
    width: 1px;
    height: 12px;
    background: #e6e6e6;
    border-radius: 1px;
    margin-right: 7px;
    margin-left: 20px;
  }

  .btn-tag {
    color: #7f7f7f;
    font-size: 12px;
    padding: 3px 4px 3px 12px;
    background: #f0f0f0;
    border-radius: 27px;
  }
}

.columns-selector {
  .mantine-Menu-item {
    padding: 5px 8px;
  }

  .mantine-Checkbox-input:checked {
    background-color: #3db9cf;
    border-color: #3db9cf;
  }

  .mantine-Checkbox-labelWrapper {
    width: 120px;
  }

  .mantine-Checkbox-label {
    color: #7f7f7f;
    font-weight: 500;
    padding-left: 10px;
  }

  .mantine-Checkbox-root {
    height: 20px;
  }

  .menu-item {
    &.active {
      .mantine-Checkbox-label {
        color: #3db9cf;
      }
    }
  }
}

.menu-item {
  font-weight: 500;
  font-size: 12.5361px;
  padding: 6px 7.5px !important;
  margin-bottom: 2px;
  border-radius: 5.37261px;
  color: #889096;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: unset;
  line-height: 1.55;

  &:hover {
    background: #f6f6f6;
  }

  &.active {
    background: #e7f9fb;
    color: #3db9cf;
  }
}
