.business-kyc-comp {
  position: relative;

  .page-title {
    font-size: 24px;
    font-weight: 600;

    @media only screen and (min-width: 827px) {
      position: absolute;
    }
  }

  .c-step {
    margin: 0 auto;
    border: 0.3px solid #dedede;
    border-radius: 7px;
    padding: 30px;

    @media only screen and (max-width: 768px) {
      padding: 24px 16px;
    }

    .s-title {
      color: #57584e;
      font-weight: 600;
    }

    .s-desc {
      color: #919191;
      font-size: 14px;
    }
  }

  form {
    margin: 0 auto;

    .o-a-r {
      gap: 7px;
      font-weight: 500;
      font-size: 14px;

      .mantine-Checkbox-root {
        transform: translateY(3px);
      }

      span {
        color: #fa5252;
      }

      .ra {
        color: rgb(145, 145, 145);
      }
    }
  }

  .view-comp-details {
    padding: 24px;
    border: 0.3px solid #dedede;
    border-radius: 7px;

    &:not(:last-of-type) {
      margin-bottom: 24px;
    }

    @media only screen and (max-width: 768px) {
      padding: 24px 16px;
    }

    .d-title {
      font-weight: 600;
      font-size: 18px;
      color: #57584e;
    }

    .d-desc {
      color: #919191;
      font-size: 14px;
      padding-bottom: 8px;
      margin-bottom: 18px;
      border-bottom: 0.3px solid #dedede;
    }

    .details-main {
      .c-detail-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 20px;
        font-weight: 500;
        font-size: 14px;

        &:not(:last-child) {
          margin-bottom: 14px;
        }

        @media only screen and (max-width: 568px) {
          font-size: 12px;
        }

        .d-i-name {
          color: #a7a7a7;
          font-weight: 400;
        }

        .d-i-value {
          color: #121212;

          max-width: 400px;

          .country {
            background: #feebdc;
            border-radius: 19px;
            padding: 4px 8px;
          }
        }
      }

      .sub-section {
        color: #57584e;
        font-weight: 700;
        padding-bottom: 5px;
        margin: 20px 0 10px;
        border-bottom: 0.3px solid #dedede;
      }
    }
  }

  .officers-group {
    border: 0.3px solid #dedede;
    border-radius: 7px;
    padding: 30px;
    margin-bottom: 20px;

    @media only screen and (max-width: 768px) {
      padding: 24px 16px;
    }

    .o-g-title {
      color: #57584e;
      font-weight: 600;

      span {
        font-weight: 500;
        color: gray;
        font-size: 14px;
      }
    }

    .o-g-desc {
      color: #919191;
      font-size: 14px;
      margin-bottom: 18px;
    }

    .officer-row {
      display: flex;
      flex-direction: row;
      gap: 12px;

      &:not(:last-of-type) {
        margin-bottom: 12px;
      }

      .o-main {
        width: calc(100% - 57px);
        background: #f8f9fa;
        height: 44px;
        border-radius: 4px;
        padding: 0 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-weight: 500;

        &.full {
          width: 100%;
        }
      }
    }

    button {
      font-weight: 500;
    }

    .mantine-Select-input {
      font-weight: 500;
      background: #f8f9fa;
    }

    .add-owner-row {
      .mantine-Select-root {
        width: calc(100% - 118.5px);
      }
    }
  }

  .org-documents {
    margin-bottom: 20px;

    .s-desc {
      margin-bottom: 18px;
    }

    .d-empty {
      text-align: center;
      margin: 50px 0;
      color: #868e96;
      font-size: 14px;
    }

    .document {
      background: #fbfbfb;
      border: 0.3px solid #dedede;
      padding: 12px;
      border-radius: 8px;

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }

      .d-name {
        font-size: 14px;
        font-size: 14px;
        color: #494949;
        font-weight: 500;

        &::after {
          content: ' *';
          color: #fa5252;
        }
      }

      .d-desc {
        color: #919191;
        font-size: 13px;
        margin-bottom: 8px;
      }

      .d-data {
        font-size: 13px;
        margin-bottom: 14px;
        font-weight: 500;
        color: #2b2bae;

        .download-icon {
          margin-right: 4px;
          transform: translateY(2px);
        }
      }

      .edit-document-row {
        .mantine-InputWrapper-root {
          width: calc(100% - 79px);
        }

        .mantine-FileInput-input {
          border-style: dashed;
          text-align: center;
        }

        .mantine-Input-input {
          border-color: #dedede;
        }
      }
    }
  }

  .mantine-Checkbox-label {
    padding-left: 8px;
  }

  .mantine-Stepper-steps {
    margin-top: 55px;

    @media only screen and (max-width: 826px) {
      margin-top: 30px;
    }
  }
}

.steps-completed {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 500px;

  @media only screen and (max-width: 768px) {
    padding: 24px 16px;
  }

  .c-title {
    font-weight: 700;
    font-size: 23px;
    margin-bottom: 12px;
  }

  .c-desc {
    color: #444444;
  }
}
