.details-drawer-bottom {
  border-radius: 10px 10px 0 0;

  @media only screen and (min-width: 1201px) {
    left: 250px;
  }
}

.audit-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
  }

  .a-d-left {
    width: 50%;
    padding-right: 16px;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .a-d-right {
    width: 49%;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    min-width: 400px;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .mantine-ScrollArea-root {
    border: unset !important;
    border-radius: unset !important;
  }
}

.filter-dropdown {
  min-height: 400px;
  position: relative;

  .f-footer {
    position: absolute;
    left: -16px;
    bottom: -12px;
    right: -16px;
    background: #f8f8f8;
    padding: 12px;

    button {
      border: 1px solid #e6e6e6;
      color: #7f7f7f;
    }
  }

  .mantine-Input-input {
    color: #3db9cf;
    font-weight: 500;

    &::placeholder {
      color: #3db9cf;
      opacity: 0.5;
    }
  }

  .mantine-Input-wrapper {
    padding: unset !important;
  }

  .mantine-ScrollArea-root {
    border: unset !important;
    border-radius: unset !important;
  }

  .filter-children-container {
    height: fit-content;
    max-height: 300px;
  }
}
