.pagination {
  .mantine-Pagination-item {
    height: 30px !important;
    border: 1.5px solid #e6e6e6;
    border-radius: 6px;
    font-size: 13px;
    color: #7f7f7f;
    font-weight: 500;

    &[data-active='true'] {
      color: #000;
      background: white;
      border: 1.5px solid #000000;
    }
  }

  .size-select {
    font-size: 13px;
    font-weight: 500;
    color: #7f7f7f;
    padding: 5px 12px;
    border: 1.5px solid #e6e6e6;
    border-radius: 6px;
  }

  .mantine-Menu-item {
    padding: 10px 12px;
    font-size: 13px;
    font-weight: 500;
  }
}
