.event-details {
  .e-block {
    height: calc(100vh - 245px);
    overflow: scroll;
    border: 1px solid #e8e8e8;
    border-radius: 6px;

    @media only screen and (max-width: 768px) {
      height: calc(100vh - 180px);
    }
  }
}
