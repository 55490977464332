.env-switch-docs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 500px) {
    gap: 10px;
  }

  .go-to-docs {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #696969;
    background: whitesmoke;
    padding: 2px 4px;
    border-radius: 3px;
    font-size: 14px;
    box-shadow: 0px 4px 10px rgba(112, 72, 37, 0.06);
    border: 1px solid #e2e2e2;

    @media only screen and (max-width: 568px) {
      font-size: 12px;
    }

    svg {
      height: 20px;
      width: 20px;
      margin-right: 2px;
    }
  }

  .bell-ic {
    width: 25px;
    height: auto;
  }

  .env-switch {
    margin-left: 24px;

    @media only screen and (max-width: 568px) {
      margin-left: 8px;
    }

    .mantine-Switch-label {
      font-weight: 500;
      padding-right: 6px;
      font-size: 16px;
    }
  }
}
