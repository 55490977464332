.card-details {
  @media only screen and (max-width: 568px) {
    padding-bottom: 28px;
  }

  &.other {
    padding: 24px;
    background: #dcdcdc;
    box-shadow: inset 0 -1px 0 0 #e9e9ea, inset 0 0 0 9999px #f7f7f7;
    border-radius: 8px;

    @media only screen and (max-width: 568px) {
      padding: 24px 12px;
    }
  }

  &.pending-transfer {
    padding-bottom: 55px;

    @media only screen and (max-width: 568px) {
      padding-bottom: 65px;
    }
  }

  .t-amount {
    font-size: 28px;
    font-weight: 600;

    @media only screen and (max-width: 568px) {
      font-size: 23px;
    }
  }

  .group-title {
    font-weight: 500;
    padding-bottom: 4px;
    border-bottom: 1px solid rgb(231, 231, 231);
    margin-top: 30px;
    margin-bottom: 8px;
  }

  .source-destination {
    font-weight: 500;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 568px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }

    .s-d-sec {
      color: #868e96;
    }

    svg {
      path {
        stroke: #f4811e;
      }

      @media only screen and (max-width: 568px) {
        margin-left: 40px;
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
  }

  .floating-btn {
    position: fixed;
    bottom: 30px;
    right: 50%;
    transform: translateX(50%);
    z-index: 1;

    @media only screen and (max-width: 568px) {
      bottom: 40px;
    }

    button {
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    }
  }
}
