.details-view {
  .d-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    text-align: center;

    .h-date {
      color: rgb(137, 137, 137);
      font-weight: 500;
    }

    .h-amount {
      font-size: 30px;
      font-weight: 600;
    }
  }

  .d-main {
    margin-top: 30px;
  }

  .d-extra-info {
    margin-top: 18px;
    padding: 24px;
    background: #dcdcdc;
    box-shadow: inset 0 -1px 0 0 #e9e9ea, inset 0 0 0 9999px #f7f7f7;
    border-radius: 8px;

    &.nest {
      background: #cbcbcb;
      box-shadow: inset 0 -1px 0 0 #e9e9ea, inset 0 0 0 9999px #ededed;
    }

    @media only screen and (max-width: 768px) {
      padding: 24px 12px;
    }

    .e-i-title {
      font-weight: 500;
      color: #f4811e;
    }

    .arr-right {
      path {
        stroke: #f4811e;
      }
    }
  }

  .resource-card-desc {
    font-size: 14px;
    color: gray;

    @media only screen and (max-width: 568px) {
      max-width: 180px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
