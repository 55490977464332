.auth-page {
  .auth-main {
    margin: 100px auto 70px;
    padding: 0 20px;
    max-width: 480px;

    &.lrgr {
      max-width: 670px;
      margin-top: 80px;
    }

    &.verify-email {
      text-align: center;

      img {
        width: 100%;
        max-width: 270px;
        height: auto;
        margin-bottom: 32px;
      }
    }

    @media only screen and (max-width: 768px) {
      margin-top: 80px;
    }

    @media only screen and (max-width: 568px) {
      margin-top: 40px;
    }

    .back-btn {
      margin-bottom: 14px;
      color: gray;
      font-weight: 500;

      svg {
        margin-right: 6px;
      }
    }

    .a-m-title {
      font-weight: 700;
      font-size: 35px;
      line-height: 120%;
      margin-bottom: 14px;

      @media only screen and (max-width: 568px) {
        font-size: 27px;
      }
    }

    .a-m-desc {
      font-size: 18px;
      line-height: 140%;
      color: #444444;
      margin-bottom: 47px;

      @media only screen and (max-width: 568px) {
        margin-bottom: 27px;
        font-size: 16px;
      }
    }

    .a-form {
      max-width: 420px;

      &.la6g {
        max-width: 740px;
      }
    }

    .sec-action {
      margin-top: 18px;
      color: #1f1f1f;
      text-align: center;

      span {
        font-weight: 500;
      }
    }
  }
}

.react-code-input {
  margin-top: 36px;
  width: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;

  input {
    width: calc(16% - 8px) !important;
    text-align: center;
    font-family: unset !important;
    padding-left: 0px !important;
    font-size: 18px !important;
    margin: 0 !important;

    &:focus-visible {
      outline: #f4811e auto 1px !important;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.error-text {
  color: #fa5252;
}
