.account-overview {
  .account-card {
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 3px,
      rgba(0, 0, 0, 0.05) 0px 10px 15px -5px,
      rgba(0, 0, 0, 0.04) 0px 7px 7px -5px;
    border: 1px solid rgb(234, 234, 234);
    border-radius: 10px;
    padding: 30px;

    @media only screen and (max-width: 768px) {
      padding: 20px;
    }

    &.mt {
      margin-top: 16px;
    }

    .card-title {
      font-weight: 500;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      color: #2e2e2e;
    }
  }

  .accunt-metrics-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }

    .account-card {
      width: 50%;

      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .balance-history {
      .balances {
        padding-bottom: 20px;
        border-bottom: 1px solid #e6e6e6;
      }
    }
  }

  .info-items {
    gap: 50px;

    @media only screen and (max-width: 768px) {
      gap: 20px;
    }
  }
}

.card-balance-item {
  &.add-opacity {
    .b-value {
      opacity: 0.5;
    }
  }

  .b-desc {
    color: #7e7e7e;
    font-size: 12px;
    margin-bottom: 3px;
  }

  .b-value {
    color: #000000;
    font-weight: 500;
    font-size: 16px;
  }
}

.account-info-item {
  .i-desc {
    font-size: 12px;
    color: #7e7e7e;
    margin-bottom: 3px;
  }

  .i-value {
    font-size: 14px;
    font-weight: 500;
    color: #2e2e2e;
  }
}
