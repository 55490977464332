.profile {
  max-width: 633px;

  .p-group {
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    margin-bottom: 50px;

    .p-g-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      color: #7f7f7f;
      font-size: 14px;

      &:not(:last-child) {
        border-bottom: 1px solid #e6e6e6;
      }
    }
  }

  .p-sub-title {
    font-size: 16px;
    font-weight: 500;
    color: #2e2e2e;
    margin-bottom: 8px;
  }

  .p-sub-desc {
    color: #7f7f7f;
    font-size: 13px;
  }
}
