.App {
  position: relative;
}

a {
  text-decoration: none;
  color: unset;
}

.click {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &:active {
    opacity: 0.7;
  }
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 0.75rem;

  &.align-end {
    align-items: flex-end;
  }

  &.align-start {
    align-items: flex-start;
  }

  @media only screen and (max-width: 530px) {
    display: block;
    margin-bottom: unset;
  }

  .form-item {
    width: 49%;

    @media only screen and (max-width: 530px) {
      width: 100% !important;
      margin-bottom: 0.75rem;
    }

    &.full {
      width: 100%;
    }
  }
}

table {
  td {
    color: #121212;
    font-weight: 500;
  }

  .table-menu {
    text-align: right !important;
    width: 20px;
  }
}

.mantine-Tabs-tab {
  @media only screen and (max-width: 530px) {
    padding: 10px !important;
  }
}

.link-new-tab {
  text-decoration: underline;
  line-height: 165%;
  font-size: 14px;
  color: #444444;

  span {
    margin-left: 4px;
    svg {
      transform: translateY(3px);
    }
  }
}

.capitalize {
  text-transform: capitalize;
}

.arrow-right-text {
  font-size: 12px;
  font-weight: 600;
  color: rgb(82, 82, 82);
  margin: 0 4px;
}

td {
  font-size: 13.2px !important;
}

.mantine-Prism-lineContent {
  white-space: pre-wrap;
}

ul {
  padding-inline-start: 20px;
}

.relative {
  position: relative;
}

.mantine-ScrollArea-thumb {
  background: #7f7f7f66;
}

.mantine-InputWrapper-label {
  color: #7f7f7f;
}

.mantine-Input-input {
  color: #2e2e2e;
  border: 1.5px solid #f8f8f8;

  &::placeholder {
    color: #7f7f7f;
  }
}

.input-container,
.global-search,
.password-input-wrapper {
  .mantine-Input-input {
    border: unset;
  }
}

.text-center {
  text-align: center;
}

.password-input-wrapper {
  .mantine-InputWrapper-root {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e6e6e6;
    padding: 12px 8px 12px 16px;
    position: relative;

    &:first-of-type {
      border-radius: 8px 8px 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 8px 8px;
    }

    &:only-of-type {
      border-radius: 8px;
    }

    &:not(:last-of-type) {
      border-bottom: unset;
    }

    .mantine-PasswordInput-wrapper {
      flex-grow: 2;
    }

    .mantine-PasswordInput-innerInput {
      text-align: right;
    }

    .mantine-InputWrapper-error {
      position: absolute;
      bottom: 4px;
      font-size: 11px;
    }
  }
}
