.business-details {
  .sections-list {
    position: fixed;
    right: 30px;
    top: 150px;

    @media only screen and (max-width: 968px) {
      display: none;
    }

    .section-item {
      font-weight: 500;
      font-size: 14px;
      color: #2e2e2e;
      opacity: 0.4;
      margin-bottom: 15px;

      @media only screen and (max-width: 1200px) {
        font-size: 13px;
      }

      &.active {
        color: #4c81b2;
        opacity: 1;
      }
    }
  }

  .page-title {
    font-size: 20px;
    font-weight: 500;
    color: #2e2e2e;
  }

  .details-main {
    .card {
      width: 80%;
      max-width: 879px;
      margin-top: 40px;
      background: #ffffff;
      border: 1px solid rgb(239, 239, 239);
      box-shadow: 0px 4px 10px rgba(37, 28, 19, 0.06);
      border-radius: 10px;
      padding: 30px;

      @media only screen and (max-width: 968px) {
        width: 100%;
      }

      @media only screen and (max-width: 768px) {
        padding: 24px;
      }

      &.reduce-mt {
        margin-top: 20px;
      }

      .card-title {
        font-weight: 500;
        color: #2e2e2e;
        display: flex;
        align-items: center;
        gap: 4px;
      }

      .card-items {
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 50px;

        @media only screen and (max-width: 768px) {
          gap: 30px;
        }

        &.extra-space {
          @media only screen and (min-width: 768px) {
            gap: 100px;
          }
        }

        .card-item {
          .card-item-title {
            color: #7e7e7e;
            font-size: 12px;
            margin-bottom: 2px;
          }

          .card-item-value {
            color: #2e2e2e;
            font-size: 14px;
            font-weight: 500;
            max-width: 446px;
          }
        }
      }

      .attachments {
        .a-title {
          color: #2e2e2e;
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 4px;
        }

        .attachment {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          max-width: 529px;
          padding: 8px 14px;
          border: 1px solid #e6e6e6;
          border-radius: 6px;
          margin-bottom: 8px;

          @media only screen and (max-width: 538px) {
            gap: 8px;
            flex-direction: column;
            align-items: flex-start;
          }

          div {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            &:nth-of-type(1) {
              font-size: 13px;
              font-weight: 500;
              color: #7f7f7f;
            }

            &:nth-of-type(2) {
              font-size: 12px;
              color: #677189;

              @media only screen and (max-width: 538px) {
                width: 100%;
                justify-content: space-between;
              }
            }
          }
        }
      }

      .alert {
        padding: 8px 16px;
        background: #f8f8f8;
        border-radius: 6px;
        color: #7f7f7f;
        font-weight: 500;
        font-size: 13px;
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }

  .light {
    color: #677189;
  }
}

.mantine-Divider-root {
  border-top-color: #ececec;
}

.badge {
  border-radius: 20px;
  padding: 5px 12px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 800;

  &.success {
    color: #51b83d;
    background: #f0fff4;
  }
}
