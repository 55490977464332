.global-search {
  .input-wrapper {
    background: white;
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 1px 10px;
    position: relative;
    box-shadow: 0px 4px 10px rgba(112, 72, 37, 0.06);
    width: 50vw;
    min-width: 380px;
    max-width: 1500px;
    display: flex;

    .mantine-InputWrapper-root {
      flex-grow: 1;
    }

    @media only screen and (max-width: 1200px) {
      width: 52vw;
    }

    @media only screen and (max-width: 768px) {
      width: calc(100vw - 78px);
      min-width: unset;
    }

    &.focused {
      border-radius: 4px 4px 0 0;
    }

    .type-badge {
      display: flex;
      gap: 5px;
      align-items: center;
      padding: 2px 6px;
      text-transform: capitalize;

      @media only screen and (max-width: 468px) {
        font-size: 12px;
      }

      span {
        transform: translateY(-1px);
      }

      svg {
        height: 16px;
        width: auto;
        opacity: 0.6;
      }
    }
  }

  .g-s-dropdown {
    display: none;
    position: absolute;
    top: 39px;
    right: 0;
    left: 0;
    transform: translateX(-1.2px);
    width: calc(100% + 2px);
    background: white;
    border-radius: 0px 0px 4px 4px;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    box-shadow: 0px 4px 10px rgba(112, 72, 37, 0.06);

    &.active {
      display: block;
    }

    .dropdown-inner {
      padding: 15px 15px 20px 15px;

      .d-title {
        font-size: 14px;
        font-weight: 500;
        color: rgb(169, 169, 169);
      }

      .resources {
        margin-top: 4px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        flex-wrap: wrap;
      }
    }
  }
}
