.confirmation-wrapper {
  .c-title {
    color: grey;
    margin-bottom: 20px;
    transform: translateY(-13px);
    font-size: 14px;
  }

  label {
    font-size: 15px !important;
  }
}

.modal-title324 {
  font-size: 20px;
  font-weight: 600;
}
