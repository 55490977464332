.info-group {
  width: 48%;
  margin-bottom: 30px;

  &.main {
    .i-g-desc {
      font-weight: 500 !important;
      font-size: 20px !important;

      @media only screen and (max-width: 568px) {
        font-size: 18px !important;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.w-full {
    width: 100%;
  }

  &.s-t {
    margin: 20px 0;

    .i-g-title {
      color: #868686 !important;
      font-weight: 700 !important;
      font-size: 18px !important;
    }
  }

  &.sm-full {
    @media only screen and (max-width: 568px) {
      width: 100%;
    }
  }

  .i-g-title {
    color: #999999;
    font-weight: 500;
    font-size: 14px;
  }

  .i-g-desc {
    font-size: 14px;
  }
}

.detail-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 13px 0;
  border-bottom: 1px solid #f0f0f0;
  font-size: 13px;
  gap: 16px;

  @media only screen and (max-width: 568px) {
    font-size: 12px;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.column-sm {
    @media only screen and (max-width: 568px) {
      flex-direction: column;
    }

    .i-desc {
      max-width: unset;
    }
  }

  &.stack {
    display: block;
    border-bottom: unset;

    .i-text {
      margin-bottom: 4px;
      width: 100%;
      max-width: unset;
    }

    .i-desc {
      text-align: left;
    }
  }

  .i-text {
    color: #7f7f7f;
    width: 30%;
    min-width: 100px;
    max-width: 260px;

    @media only screen and (max-width: 768px) {
      max-width: 160px;
    }
  }

  .i-desc {
    font-weight: 500;
    color: #2e2e2e;
    text-align: right;
    max-width: 400px;

    .resource-link {
      color: #3db9cf;
      text-decoration: underline;
    }
  }
}

.permissions-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;

  .permission-type {
    color: #889096;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    padding: 3px 6px;
    border-radius: 27px;

    &.gray {
      background: #f1f3f5;
    }

    &.green {
      background: #e1fbf4;
    }

    &.yellow {
      background: rgba(240, 200, 62, 0.1);
    }

    &.red {
      background: #feeff6;
    }
  }
}
