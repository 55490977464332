.metric-card {
  border: 0.5px solid #dddddd;
  box-shadow: 0px 4px 10px rgba(112, 72, 37, 0.06);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 20px;

  .m-c-info {
    margin-left: 20px;

    .m-title {
      color: #999999;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 4px;
    }

    .m-value {
      font-weight: 700;
      font-size: 18px;
    }
  }
}
